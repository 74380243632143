import Button from '@/labtest/presentation/atoms/Button';
import OrderCancelReason from '@/labtest/presentation/molecules/OrderCancelReason';

import {
	v1_delete_error_icon,
	v1_lab_science,
	v1_not_available_service_image,
	v1_not_available_test_image,
	v1_order_cancel_image
} from '@labServices/utils/svg';

import styles from './PopUpModal.module.css';

const PopUpModal = ({
	reason,
	setReason,
	title,
	errorTag,
	message,
	modalName,
	modalType,
	cancelDelete,
	confirmDelete
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.icon_container_wrap}>
				{modalType === ModalType.DELETE && <div className={styles.icon_container}>{v1_delete_error_icon}</div>}
				{modalType === ModalType.ORDER_CANCEL && (
					<div className={`${styles.icon_container} ${styles.icon_container_bg}`}>{v1_order_cancel_image}</div>
				)}

				{modalType === ModalType.NOT_AVAILABLE && errorTag && (
					<div className={styles.icon_container}>
						{errorTag === ErrorTag.ITEM_UNAVAILABLE && <>{v1_not_available_test_image}</>}
						{errorTag === ErrorTag.SERVICE_UNAVAILABLE && <>{v1_not_available_service_image}</>}
						{errorTag === ErrorTag.ITEM_MAX_REACHED && <>{v1_lab_science}</>}
						{errorTag === ErrorTag.ORDER_IN_MAINTENANCE && (
							<div className={`${styles.icon_container}`}>{v1_delete_error_icon}</div>
						)}
					</div>
				)}

				{/* Default */}
				{modalType === ModalType.NOT_AVAILABLE && !errorTag && (
					<div className={styles.icon_container}>{v1_delete_error_icon}</div>
				)}

				<div className={styles.content_container}>
					<p>{title || 'Sorry'}</p>
					<p>{message || 'Internal Server Error'}</p>
				</div>
			</div>

			{modalName === ModalName.CANCEL_ORDER && (
				<div className={styles.reasons_container_wrap}>
					<OrderCancelReason reason={reason} setReason={setReason} />
				</div>
			)}

			<div
				className={`${styles.button_container} ${modalType === ModalType.ORDER_CANCEL && styles.button_container_orderCancel}`}>
				{modalType === ModalType.NOT_AVAILABLE && (
					<Button variant='bgPrimary' fullWidth onClick={cancelDelete}>
						Ok
					</Button>
				)}

				{modalType === ModalType.DELETE && (
					<>
						<Button variant='bgPrimary' fullWidth onClick={cancelDelete}>
							No
						</Button>

						<Button variant='bgError' fullWidth onClick={confirmDelete}>
							Yes
						</Button>
					</>
				)}

				{modalType === ModalType.ORDER_CANCEL && (
					<>
						<Button variant='bgError' fullWidth onClick={cancelDelete}>
							No
						</Button>
						<Button variant='bgPrimary' fullWidth onClick={confirmDelete}>
							Submit
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default PopUpModal;

const ModalName = {
	CANCEL_ORDER: 'cancel_order_modal'
};

const ModalType = {
	DELETE: 'delete',
	ORDER_CANCEL: 'order_cancel',
	NOT_AVAILABLE: 'not_available'
};

const ErrorTag = {
	ORDER_CANCEL: 'order_cancel',
	ITEM_UNAVAILABLE: 'item_unavailable',
	SERVICE_UNAVAILABLE: 'service_unavailable',
	ITEM_MAX_REACHED: 'item_max_reached',
	ORDER_IN_MAINTENANCE: 'order_in_maintenance'
};
