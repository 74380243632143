import useOutsideClick from '@labServices/hooks/useOutsideClick';
import { useRef } from 'react';

import styles from './SideDrawer.module.css';

const SideDrawer = ({ open, onClose, title, children }) => {
	const ref = useRef();
	useOutsideClick(ref, onClose);

	return (
		<>
			{/* {title === 'Package Details' && (
        <div
          ref={ref}
          className={`${styles.sideDrawer_close_icon} ${
            open ? styles.open : ''
          }`}
        >
          <i onClick={onClose} className="fa-solid fa-xmark fa-lg" />
        </div>
      )} */}
			<div ref={ref} className={`${styles.sideDrawer} ${open ? styles.open : ''}`}>
				{title !== 'Package Details' && (
					<div className={styles.sideDrawer_head}>
						<p className='ltbody_xlg__semibold ltGreyscale900'>{title || 'Cart'}</p>
						<i onClick={onClose} className='fa-solid fa-xmark fa-lg' />
					</div>
				)}

				{children}
			</div>
		</>
	);
};

export default SideDrawer;
