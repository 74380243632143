import Line from '@/labtest/presentation/atoms/Line';
import { MdClose } from 'react-icons/md';
import styles from './ModalHeader.module.css';

interface ModalHeader {
	name?: string;
	title: string;
	titleColor?: string;
	closeModal: () => void;
	notClose?: boolean;
}

const ModalHeader = ({ name, title, titleColor, closeModal, notClose }: ModalHeader): JSX.Element => {
	return (
		<>
			<div className={styles.dialog_head}>
				<p className={`${titleColor ? titleColor : 'ltGreyscale900'} ltbody_xlg__semibold `}>{title}</p>
				{!notClose && (
					<MdClose
						size={22}
						className='cursor_pointer errorBase'
						onClick={(e) => {
							e.stopPropagation();
							closeModal();
						}}
					/>
				)}
			</div>
			{!name && <Line />}
		</>
	);
};

export default ModalHeader;
