import styles from './Modal.module.css';

const StateFullModal = ({ open, children, closeModal }) => {
	// useOutsideClick(ref, closeModal);
	return (
		<div className={`${styles.modal} ${open ? styles.open : ''}`} onClick={(e) => e.stopPropagation()}>
			<div className={styles.modal_body}>{children}</div>
		</div>
	);
};

export default StateFullModal;
