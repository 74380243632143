import styles from './TextField.module.css';

const TextField = ({ placeholder, maxLength, rows, value, autoFocus, ...rest }) => {
	return (
		<div className={`${styles.textfield_wrap} ${value?.length == maxLength && styles.errorDark}`}>
			<textarea
				{...rest}
				type='text'
				autoFocus={autoFocus}
				placeholder={placeholder || 'write here...'}
				maxLength={maxLength}
				rows={rows || '4'}
				className={styles.textfield}
			/>
			<p className='ltbody_sm__regular flex justify-end'>
				{value.length}/{maxLength || '120'}
			</p>
		</div>
	);
};

export default TextField;
