import styles from './Line.module.css';

interface I_LineProps {
	dashed?: string;
}
const Line = ({ dashed }: I_LineProps) => {
	return <div className={`${styles.line} ${dashed ? styles.dashed : ''}`} />;
};

export default Line;
