import styles from './DiscountChip.module.css';

interface I_DiscountChipProps {
	text: string;
	height?: 'height_12' | 'height_15' | 'height_18' | 'height_22' | 'height_30' | 'height_32';
	chipItem?: 'testCard' | 'cart';
}

const DiscountChip = ({ text, height, chipItem = 'testCard' }) => {
	return (
		<div className={`${styles.discount_chip} ${height ? styles[`height_${height}`] : ''}`}>
			<div className={`${styles.chip} ${styles[chipItem]}`}>
				<p className={`${styles.chip_text} ${chipItem === 'cart' ? 'ltbody_xs__medium' : 'ltbody_md__regular'}`}>
					{text}
				</p>
			</div>
		</div>
	);
};

export default DiscountChip;
