import { toQueryString } from '@labServices/utils/cmnHelper';
import { request } from '../arogga-api';

import querystring from 'qs';

export const getLabItemsSearchData = (q: any) => {
	if ('_perPage' in q) {
		q['limit'] = q._perPage;
	}
	if ('_page' in q) {
		q['page'] = q._page;
	}
	if ('_search' in q) {
		q['query'] = q._search;
	}

	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/search?${qs}`,
		method: 'GET'
	});
};

export const getLabItemsTrendingSearchData = (
	q = {
		_page: 1,
		_perPage: 10,
		_search: ''
	}
) => {
	if ('_perPage' in q) {
		q['limit'] = q._perPage;
	}
	if ('_page' in q) {
		q['page'] = q._page;
	}
	if ('_search' in q) {
		q['query'] = q._search;
	}
	const qs = querystring.stringify(q);

	return request({
		endpoint: `lab-search/api/v1/search/trending?${qs}`,
		method: 'GET'
	});
};

export const getFrequentLabItems = (q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/frequent?${qs}`,
		method: 'GET'
	});
};

export const getPopularLabItems = (q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/popular?${qs}`,
		method: 'GET'
	});
};

export const getLabTestsCategories = (q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/category?${qs}`,
		method: 'GET'
	});
};

export const getLabTestsFilterCategories = (q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/search/category?${qs}`,
		method: 'GET'
	});
};
export const GetSearchCounts = (q = '') => {
	return request({
		endpoint: `lab-search/api/v1/search/category?query=${q}&limit=1`,
		method: 'GET'
	});
};

export const getLabTestsFilterSections = (q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/search/sections?${qs}`,
		method: 'GET'
	});
};

export const getLabTests = (q: Record<string, any>) => {
	const qs = toQueryString(q);
	return request({
		endpoint: `misc/api/v1/lab-items?${qs}`,
		method: 'GET'
	});
};

export const getPackageDetails = (id: string, q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/${id}`,
		method: 'GET'
	});
};

export const getRecommendationLabTestDetails = (id: string, q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/recommendation?itemId=${id}`,
		method: 'GET'
	});
};

export const getTestProcessSteps = (id: string, q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-steps`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};

export const getRiskAssessmentLabTestDetails = (id: string, q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/details?itemId=${id}`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};

export const getFAQLabTestDetails = (id: string, q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/faq?itemId=${id}`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};

export const getMinMaxPriceRange = () => {
	return request({
		endpoint: `misc/api/v1/lab-items/price-range`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};
