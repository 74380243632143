import Line from '@/labtest/presentation/atoms/Line';
import Radio from '@labtest/presentation/atoms/Radio';
import TextField from '@labtest/presentation/atoms/TextField';

import styles from './OrderCancelReason.module.scss';

const OrderCancelReason = ({ reason, setReason }) => {
	const handleCheckboxChange = (name) => {
		setReason((prev) => ({ ...prev, selectedReason: name || '' }));
	};

	return (
		<>
			<Line />
			<div className={styles.cancel_reason_container}>
				<div className={styles.lists_wrap}>
					{reason?.allReasons?.map((slReason, i) => (
						<div key={i} className={styles.listItem}>
							<Radio
								name={'reasons'}
								onChange={() => handleCheckboxChange(slReason?.en)}
								checked={reason?.selectedReason || 'Ordered by mistake' || 'Booked by mistake'}
								label={slReason?.en}
								btnSize={`20px`}
								textSize={`14px_400`}
							/>
						</div>
					))}
				</div>
			</div>

			{reason?.selectedReason === 'Other' && (
				<div className={styles.other_reason_container}>
					<div className={styles.textfeild}>
						<TextField
							placeholder='Reason for cancellation?'
							value={reason?.otherReason}
							onChange={(e) =>
								setReason((prev) => ({
									...prev,
									otherReason: e.target.value
								}))
							}
							autoFocus={true}
							maxLength={120}
							rows={2}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default OrderCancelReason;
