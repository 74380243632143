import { MdArrowDropDown } from 'react-icons/md';
import styles from './Dropdown.module.css';

const Dropdown = ({
	btnVariant,
	textVariant = 'primaryBase',
	placeHolder,
	value,
	onClick,
	fullWidth,
	btnSize,
	textSize = 'ltbody_lg__regular',
	iconLeft,
	iconRight,
	children
}) => {
	return (
		<div className={`${styles.dropdown_container} relative`}>
			<div
				onClick={onClick}
				className={`
					${styles.selected_item} ${placeHolder === '1 Patient' ? styles[`patient_1`] : ''}  
					${btnSize ? styles[btnSize] : ''} 
					${fullWidth ? styles.fullWidth : ''} 
					${btnVariant ? styles[btnVariant] : ''}
					`}>
				<p
					className={`
						${textSize} 
						${textVariant}
						flex gap-10 capitalize
						`}>
					{iconLeft ? iconLeft : null}
					{value || placeHolder || 'Select'}
				</p>
				{iconRight ? iconRight : <MdArrowDropDown size={24} />}
			</div>
			{children}
		</div>
	);
};

export default Dropdown;
