import ListItem from '@/labtest/presentation/atoms/ListItem';
import { getPackageDetails } from '@labServices/apis/lab-test-apis/tests';
import { isEmptyArray } from '@labServices/utils/cmnHelper';
import { v1_lab_science } from '@labServices/utils/svg';
import Spinner from '@labtest/presentation/atoms/Loaders/Spinners';
import { useEffect, useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
import style from './PackageDetailsDrawer.module.css';

const PackageDetailsDrawer = ({ slug, data, onClose }) => {
	const [packageDetailsData, setPacakgeDetailsData] = useState(null);
	const [loading, setloading] = useState([]);

	useEffect(() => {
		setloading(true);
		const getLabtestdata = async () => {
			const labTestsData = await getPackageDetails(slug);
			if (labTestsData && labTestsData.success) {
				setPacakgeDetailsData(labTestsData.data);
			}
			setloading(false);
		};

		if (slug) getLabtestdata();
		if (data) {
			setPacakgeDetailsData(data);
			setloading(false);
		}
	}, [slug, data]);

	return (
		<>
			<div className={style.testDetails_head}>
				<div>
					<span>{v1_lab_science}</span>
				</div>
				<div>
					<p className='ltbody_lg__semibold'>{packageDetailsData?.name?.en}</p>
					<p className='ltbody_lg__regular'>
						{packageDetailsData?.testCount
							? packageDetailsData?.testCount <= 1
								? '1 Test'
								: `${packageDetailsData?.testCount} Tests`
							: '1 Test'}
					</p>
				</div>
				<div className={style.close_icon_wrap} onClick={onClose}>
					<FaXmark style={24} />
				</div>
			</div>

			{loading && <Spinner />}

			{!loading && !isEmptyArray(packageDetailsData?.tests) && (
				<div className={style.testDetails_lists}>
					{packageDetailsData?.tests?.map((test) => {
						return (
							<ListItem
								key={test.id}
								name={test?.test?.name?.en || test?.name?.en}
								size={24}
								textVariant={'ltbody_lg__regular'}
							/>
						);
					})}
				</div>
			)}
		</>
	);
};

export default PackageDetailsDrawer;
