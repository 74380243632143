import { FaCheckCircle } from 'react-icons/fa';

import style from './ListItem.module.css';

const ListItem = ({ name, icon, size, textVariant }) => {
	return (
		<div className={style.testDetails_lists_text}>
			<div className={style.icon}>
				<FaCheckCircle
					size={size}
					style={{
						color: 'var(--ltPrimary)'
					}}
				/>
			</div>
			<p className={`${textVariant} ltGreyscale900`}>{name}</p>
		</div>
	);
};

export default ListItem;
