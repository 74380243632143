'use client';

import { useEffect, useRef } from 'react';
import styles from './Modal.module.css';

function Modal({ name, openModal, closeModal, children }) {
	const ref = useRef();

	useEffect(() => {
		if (openModal) {
			ref.current?.showModal();
		} else {
			ref.current?.close();
		}
	}, [openModal]);

	return (
		<dialog ref={ref} className={styles.dialog} onClick={(e) => e.stopPropagation()} onCancel={closeModal}>
			<div className={`${styles.dialog_body} ${styles[`_${name}`]}`}>{children}</div>
		</dialog>
	);
}

// const Modal = forwardRef(ModalWrap);
export default Modal;
